<script setup>
import {navigateTo} from "#app";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useNotifications} from "~/stores/notifications.js";
import {useSignInButton} from "~/composables/general/handleSignInButton.js";

const generalApp = useGeneralApp()
const notifications = useNotifications()
const handleClick = async () => {
  useSignInButton()
}
</script>

<template>
  <div class="flex flex-row justify-center direction-horizontal w-full pt-1 pb-1 border-black bg-transparent">
    <button class="w-[290px] h-[52px] rounded bg-cement-orange text-white p-4"
            v-if="generalApp.userPhone.toString().length === 11"
            @click="handleClick()">
      Вход / Регистрация
    </button>
    <button class="w-[290px] h-[52px] rounded bg-cement-orange-non-active text-white p-4"
            v-if="generalApp.userPhone.toString().length !== 11"
            >
      Вход / Регистрация
    </button>
  </div>
</template>

<style scoped>

</style>