import {useGeneralApp} from "~/stores/generalApp.js";
import {useNotifications} from "~/stores/notifications.js";
import {navigateTo} from "#app";


export const useSignInButton = () => {
    const generalApp = useGeneralApp()
    const notifications = useNotifications()
    if (/((7)+([0-9]){10})$/.test(generalApp.userPhone.toString())) {
        navigateTo('/sign-in')
    } else {
        notifications.wrongPhoneFormat = true
        setTimeout(() => {
            notifications.wrongPhoneFormat = false
        }, 3000)
    }
}