<script setup>

import HomeAndMyTicketsBlock from "~/components/NavigationMenu/HomeAndMyTicketsBlock.vue";
import NewTicket from "~/components/NavigationMenu/NewTicket.vue";
import {useUserStore} from "~/stores/user";
import SendNewTicket from "~/components/NavigationMenu/SendNewTicket.vue";

const userStore = useUserStore()
const route = useRoute()

</script>

<template>

    <HomeAndMyTicketsBlock />
</template>

<style scoped>

</style>