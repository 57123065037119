<script setup>
import {useNotifications} from "~/stores/notifications.js";
import {navigateTo} from "#app";
import {useGeneralApp} from "~/stores/generalApp.js";

const notifications = useNotifications()
const generalApp = useGeneralApp()
const handleCloseModal = () => {
  const modal = document.getElementById("leave-comment-draft");
  modal.close();
  notifications.leaveCommentDraft = false
}
const handleLeaveCreateForm = () => {
  handleCloseModal()
  generalApp.replyComment = {
    reply: false,
    commentID: '',
    commentAuthorName: ''
  }
  generalApp.commentsDisplay = false
  clearInterval(generalApp.refreshTicketData)
  navigateTo(notifications.leaveCommentDraftTo)
}
</script>

<template>
  <dialog class="flex flex-col justify-center items-center gap-4 bg-transparent"
          id="leave-comment-draft">
    <div class="w-[290px] h-[332px] bg-white p-[20px] flex flex-col justify-center items-center gap-[15px]">
      <h2 class="text-black font-bold text-[16px] text-center">
        Оставить комментарий не сохранённым?
      </h2>
      <p class="text-black text-[14px] text-center">
        Покидая сообщение о риске вы потееряется набранное сообщение.
      </p>
      <button @click="handleCloseModal()"
              class="bg-cement-orange text-white text-[14px] w-[240px] h-[45px] p-1 rounded">
        Продолжить редактирование
      </button>
      <p class="text-black text-[14px] text-center">
        или
      </p>
      <button @click="handleLeaveCreateForm()"
              class="bg-white text-black text-[14px] w-[240px] h-[45px] p-1 ring-cement-orange ring-1 rounded">
        Покинуть
      </button>
    </div>
  </dialog>
</template>

<style scoped>
dialog::backdrop {
  background: rgba(26, 26, 26, 0.75);
}
</style>