<script setup>
import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {useNotifications} from "~/stores/notifications.js";
import {navigateTo} from "#app";
import {useCheckPermissions} from "~/composables/general/checkPermissions.js";

const props = defineProps({
  notificationType: {
    type: String,
    required: true,
  },
  pushID: {
    type: Number,
    required: false
  },
  path: {
    type: String,
    required: false
  },
  event: {
    type: String,
    required: false
  }
});
const generalApp = useGeneralApp()
const userStore = useUserStore()
const notifications = useNotifications()
const config = useNotifications()
const { notificationType } = props;
const { pushID } = props
const { path } = props
const { event } = props

const closeNotification = async () => {
  switch (notificationType) {
    case 'userName': {
      notifications.setUserName = false
      break
    }
    case 'userWorkPlace': {
      notifications.setWorkPlace = false
      break
    }
    case 'push': {
      notifications.listOfPushNotifications.splice(notifications.listOfPushNotifications.indexOf(notification => notification.ticket_id === pushID))
      break
    }
    case 'permission': {
      let deniedList = await useCheckPermissions()
      console.log(deniedList)
      if (deniedList.length < 0) {
        notifications.permissionDeniedNotification = false
      }
      break
    }
  }
}
const navigateFromNotification = async () => {
  switch (notificationType) {
    case 'userName': {
      navigateTo('/user/profile-settings')
      break
    }
    case 'userWorkPlace': {
      navigateTo('/user/profile-settings')
      break
    }
    case 'push': {
      notifications.listOfPushNotifications.splice(notifications.listOfPushNotifications.indexOf(notification => notification.ticket_id === pushID))
      generalApp.currentListOfTicketNotifications.splice(generalApp.currentListOfTicketNotifications.indexOf(notification => notification.ticket_id === pushID))
      if (event === 'newMessage') {
        generalApp.commentsDisplay = true
      }
      navigateTo(path)
      break
    }
    case 'permission': {
      let deniedList = await useCheckPermissions()
      console.log(deniedList)
      if (deniedList.length < 0) {
        notifications.permissionDeniedNotification = false
      }
      break
    }
  }
}
</script>

<template>
  <div class="flex flex-col gap-1 w-full mt-[10px]">
    <div class="flex flex-row justify-between items-center w-full bg-asphalt min-h-[59px] p-[10px]" @click="navigateFromNotification">
      <svg v-if="notificationType !== 'permission'" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_56_2178)">
          <mask id="path-1-outside-1_56_2178" maskUnits="userSpaceOnUse" x="-0.00585938" y="0" width="41" height="41" fill="black">
            <rect fill="white" x="-0.00585938" width="41" height="41"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0001 39.012C30.4969 39.012 39.0062 30.5027 39.0062 20.006C39.0062 9.50928 30.4969 1 20.0001 1C9.50342 1 0.994141 9.50928 0.994141 20.006C0.994141 24.4183 2.49767 28.4794 5.02034 31.705L3.00086 39.2418L11.441 36.9803C14.0133 38.2799 16.9212 39.012 20.0001 39.012Z"/>
          </mask>
          <path d="M5.02034 31.705L5.98627 31.9638C6.06757 31.6604 6.00157 31.3364 5.80805 31.0889L5.02034 31.705ZM3.00086 39.2418L2.03493 38.983C1.94247 39.3281 2.04113 39.6963 2.29375 39.9489C2.54638 40.2015 2.91459 40.3002 3.25968 40.2077L3.00086 39.2418ZM11.441 36.9803L11.892 36.0877C11.6724 35.9768 11.4197 35.9507 11.1822 36.0143L11.441 36.9803ZM38.0062 20.006C38.0062 29.9505 29.9446 38.012 20.0001 38.012V40.012C31.0492 40.012 40.0062 31.055 40.0062 20.006H38.0062ZM20.0001 2C29.9446 2 38.0062 10.0616 38.0062 20.006H40.0062C40.0062 8.95699 31.0492 0 20.0001 0V2ZM1.99414 20.006C1.99414 10.0616 10.0557 2 20.0001 2V0C8.95114 0 -0.00585938 8.95699 -0.00585938 20.006H1.99414ZM5.80805 31.0889C3.41804 28.033 1.99414 24.1872 1.99414 20.006H-0.00585938C-0.00585938 24.6494 1.5773 28.9258 4.23264 32.321L5.80805 31.0889ZM3.96679 39.5006L5.98627 31.9638L4.05442 31.4462L2.03493 38.983L3.96679 39.5006ZM11.1822 36.0143L2.74204 38.2759L3.25968 40.2077L11.6998 37.9462L11.1822 36.0143ZM20.0001 38.012C17.0814 38.012 14.3277 37.3183 11.892 36.0877L10.9901 37.8728C13.699 39.2415 16.761 40.012 20.0001 40.012V38.012Z" fill="#E5E5E5" mask="url(#path-1-outside-1_56_2178)"/>
          <circle cx="15.5" cy="20.5" r="1.5" fill="#E5E5E5"/>
          <circle cx="20.5" cy="20.5" r="1.5" fill="#E5E5E5"/>
          <circle cx="25.5" cy="20.5" r="1.5" fill="#E5E5E5"/>
        </g>
        <defs>
          <clipPath id="clip0_56_2178">
            <rect width="40" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <img v-if="notificationType === 'permission'" src="/img/alert.svg" height="40" width="40"/>
      <p class="text-white text-[12px] text-start">
        <slot></slot>
      </p>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 3L17 12L8 21" stroke="white" stroke-linecap="round"/>
      </svg>
    </div>
    <button @click="closeNotification" v-if="notificationType !== 'permission'" class="flex flex-row justify-center items-center w-full text-[10px] text-center text-orange-light bg-asphalt min-h-[24px] p-[2px]">
      Скрыть уведомление
    </button>
  </div>
</template>

<style scoped>

</style>