import {useUserStore} from "~/stores/user.js";
import {UseCreatePendingData} from "~/composables/IDB/pending/createPendingData.js";
import {UseGetData} from "~/composables/getData.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useGetTicketList} from "~/composables/general/getTicketList.js";


export const useSendNewTicket = async () => {
    const userStore = useUserStore()
    const generalApp = useGeneralApp()
    try {
        userStore.newTicket.id = Math.floor(Math.random() * 1000).toString();
        userStore.lastTicketID = userStore.newTicket.id
        userStore.newTicket.user_id = userStore.testUser.id
        userStore.newTicket.date = new Date().toISOString()
        userStore.newTicket.status = 'Отправляется...'
        if (userStore.newAudio.file !== null) {
            userStore.newTicket.media_attachments.push(userStore.newAudio.file)
            userStore.newAudio = {
                file: null,
                stream: null,
                mediaRecorder: null,
                recordChunk: [],
                url: ''
            }
        }
        console.log(Object.values(userStore.newTicket).every(attr => !!attr))
        console.log(userStore.newTicket)
        generalApp.currentListOfTickets.unshift(userStore.newTicket)
        await UseCreatePendingData(userStore.newTicket.id, 'new_ticket', userStore.newTicket).then( async () => {
            /*let newTicket = await UseGetData('pending', `new_ticket/${userStore.newTicket.id}`)
            userStore.testUser.user_tickets.unshift(newTicket.data)*/
        })
        userStore.newTicket = {
            id: '',
            status: '',
            number: '',
            date: '',
            user_id: '',
            emergency_type: '',
            description: '',
            danger_category: '',
            danger_zone: '',
            danger_sub_zone: '',
            media_attachments: [],
            comment: '',
            notifications: true,
            subject: ''
        }
        generalApp.ticketDone = true
        userStore.timer_for_ticket_done = setInterval(() => {
            if ((generalApp.remainTime - 1000) > 0) {
                generalApp.remainTime = generalApp.remainTime - 1000;
            } else {
                generalApp.remainTime = 0
                generalApp.ticketDone = false
                clearInterval(userStore.timer_for_ticket_done)
                navigateTo(`/my-tickets`)
            }
        }, 1000)
    } catch (error) {
        console.log('error during send new ticket', error)
    }
}