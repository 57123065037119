<script setup>
import {useSendNewTicket} from "~/composables/general/sendNewTicket.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {storeToRefs} from "pinia";
import {useNotifications} from "~/stores/notifications.js";

const generalApp = useGeneralApp()
const userStore = useUserStore()
const notifications = useNotifications()
const { new_ticket_category,
  new_ticket_sub_category,
  new_ticket_emergency_type,
  new_ticket_description,
  new_ticket_zone,
  new_ticket_sub_zone} = storeToRefs(userStore)
const requiredFields = ref([
    userStore.newTicket.danger_category,
    userStore.newTicket.emergency_type,
    userStore.newTicket.description,
    userStore.newTicket.danger_zone,
    userStore.newTicket.danger_sub_zone
])
const markUnfilled = () => {
    for (let index in requiredFields.value) {
      if (requiredFields.value[index] === '') {
        notifications.intensiveMarkRequiredFields = true
        switch (index) {
          case "0": {
            new_ticket_category.value.focus()
            break
          }
          case "2": {
            new_ticket_emergency_type.value.focus()
            break
          }
          case "3": {
            new_ticket_description.value.focus()
            break
          }
          case "4": {
            new_ticket_zone.value.focus()
            break
          }
          case "5": {
            new_ticket_sub_zone.value.focus()
            break
          }
        }
        setTimeout(() => {
          notifications.intensiveMarkRequiredFields = false
        }, 3000)
        break
      }
    }
}
const clearForm = () => {
  userStore.newTicket = {
    id: '',
    status: '',
    number: '',
    date: '',
    user_id: '',
    emergency_type: '',
    description: '',
    danger_category: '',
    danger_zone: '',
    danger_sub_zone: '',
    media_attachments: [],
    comment: '',
    notifications: true,
    subject: ''
  }
  userStore.newAudio = {
    file: null,
    stream: null,
    mediaRecorder: null,
    recordChunk: [],
    url: ''
  }
}
</script>

<template>
  <div v-if="((userStore.newTicket.danger_category !== '')&&
              (userStore.newTicket.danger_zone !== '')&&
              (userStore.newTicket.danger_sub_zone !== '')&&
              (userStore.newTicket.emergency_type !== '')&&
              (userStore.newTicket.description !== ''))"
      class="mb-[10px] px-[10px] w-full text-white flex flex-row justify-between items-center gap-[1%] h-[35px]">
    <button @click="clearForm()" class="w-[40.5%] z-20 p-2 border-black bg-grey-border text-black opacity-90 rounded text-[14px]">
      Отчистить форму
    </button>
    <button @click="useSendNewTicket()" class="w-[40.5%] z-20 p-2 border-black bg-cement-orange rounded text-[14px]">
      Отправить
    </button>
  </div>
  <div v-if="((userStore.newTicket.danger_category === '')||
              (userStore.newTicket.danger_zone === '')||
              (userStore.newTicket.danger_sub_zone === '')||
              (userStore.newTicket.emergency_type === '')||
              (userStore.newTicket.description === ''))"
      class="z-[100] mb-[10px] px-[10px] w-full text-white flex flex-row justify-between items-center gap-[1%] h-[35px]">
    <button @click="clearForm()" class="w-[40.5%] z-20 p-2 border-black bg-grey-border text-black opacity-90 rounded text-[14px]">
      Отчистить форму
    </button>
    <button @click="markUnfilled()" class="w-[40.5%] p-2 border-black bg-cement-orange-non-active rounded text-[14px]">
      Отправить
    </button>
  </div>
</template>