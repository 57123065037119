import {useUserStore} from "~/stores/user.js";
import {useNotifications} from "~/stores/notifications.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {navigateTo} from "#app";

export const useHandleBackButton = async () => {
    try {
        const route = useRoute();
        const userStore = useUserStore()
        const generalApp = useGeneralApp()
        const notifications = useNotifications()
        if (generalApp.requestProcessing) {
        } else {
            switch (route.path) {

                case '/my-tickets/create-new': {
                    notifications.leaveCreateForm = true
                    notifications.leaveCreateFromTo = `/my-tickets`
                    const modalLeave = document.getElementById('leave-create-form-dialog')
                    modalLeave.showModal();
                    break;
                }
                case '/sign-in': {
                    navigateTo(`/`)
                    break;
                }
                case '/confirm': {
                    navigateTo(`/sign-in`)
                    break;
                }
                case '/user/profile-settings': {
                    notifications.leaveSettings = true
                    const modalLeave = document.getElementById('leave-settings-dialog')
                    modalLeave.showModal();
                    break;
                }
                case '/user/appearance': {
                    notifications.leaveSettings = true
                    const modalLeave = document.getElementById('leave-settings-dialog')
                    modalLeave.showModal();
                    break;
                }
                case '/user/notifications': {
                    notifications.leaveSettings = true
                    const modalLeave = document.getElementById('leave-settings-dialog')
                    modalLeave.showModal();
                    break;
                }
            }
            if (route.path.split('/')[2] === 'ticket') {
                if ((userStore.newComment.text !== '')||(userStore.newComment.media.length > 0)) {
                    notifications.leaveCommentDraft = true
                    notifications.leaveCommentDraftTo = `/my-tickets`
                    const modalLeave = document.getElementById('leave-comment-draft')
                    modalLeave.showModal();
                } else {
                    clearInterval(generalApp.refreshTicketData)
                    generalApp.commentsDisplay = false
                    navigateTo(`/my-tickets`);
                }
            }
            if (route.path.split('/')[1] === 'assignments') {
                if ((userStore.newComment.text !== '')||(userStore.newComment.media.length > 0)) {
                    notifications.leaveCommentDraft = true
                    notifications.leaveCommentDraftTo = `/assignments`
                    const modalLeave = document.getElementById('leave-comment-draft')
                    modalLeave.showModal();
                } else {
                    clearInterval(generalApp.refreshTicketData)
                    generalApp.commentsDisplay = false
                    navigateTo(`/assignments`);
                }
            }
            if (route.path === `/user/${userStore.testUser.id}`) {
                navigateTo(`/`)
            }
        }
    } catch (e) {
        console.log(e, 'error during back button')
    }
}