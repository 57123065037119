<script setup>
import {useNotifications} from "~/stores/notifications";
import {navigateTo} from "#app";
import {useUserStore} from "~/stores/user.js";
import {useHandleUpdateUserProfile} from "~/composables/general/handleUpdateUserProfile.js";
import {useHandleChangeAppearance} from "~/composables/general/handleChangeAppearance.js";
import {useGeneralApp} from "~/stores/generalApp.js";

const route = useRoute()
const generalApp = useGeneralApp()
const notifications = useNotifications()
const userStore = useUserStore()
const handleCloseModal = () => {
  const modal = document.getElementById("leave-settings-dialog");
  modal.close();
  notifications.leaveSettings = false
}
const handleReturn = () => {
  handleCloseModal()
  generalApp.tempPlaces = generalApp.setActivePlaces
  navigateTo(`/user/${userStore.testUser.id}`)
}
const handleSaveSettings = async () => {
  switch (route.path) {
    case '/user/profile-settings': {
      await useHandleUpdateUserProfile()
      break
    }
    case '/user/appearance': {
      await useHandleChangeAppearance()
      break
    }
    case '/user/notifications': {
      break
    }
  }
  handleCloseModal()
}
</script>

<template>
  <dialog class="flex flex-col justify-center items-center gap-4 bg-transparent"
          id="leave-settings-dialog">
    <div class="w-[290px] h-[332px] bg-white p-[20px] flex flex-col justify-center items-center gap-[15px]">
      <h2 class="text-black font-bold text-[16px] text-center">
        При возврате все несохранённые изменения будут утеряны.
      </h2>
      <button @click="handleSaveSettings()"
              class="bg-cement-orange text-white text-[14px] w-[240px] min-h-[45px] p-1 rounded">
        Сохранить и вернуться <br> к настройкам
      </button>
      <p class="text-black text-[14px] text-center">
        или
      </p>
      <button @click="handleReturn()"
              class="bg-white text-black text-[14px] w-[240px] h-[45px] p-1 ring-cement-orange ring-1 rounded">
        В настройки без сохранения
      </button>
    </div>
  </dialog>
</template>

<style scoped>
dialog::backdrop {
  background: rgba(26, 26, 26, 0.75);
}
</style>