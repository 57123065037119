<script setup>
import {useUserStore} from "~/stores/user";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useNotifications} from "~/stores/notifications.js";
import {navigateTo} from "#app";
const route = useRoute()
const notifications = useNotifications()
const generalApp = useGeneralApp()
const userStore = useUserStore()
const handleOpenModal = (name, destination) => {
  let modalLeave
  switch (name) {
    case 'CreateForm': {
      notifications.leaveCreateForm = true
      notifications.leaveCreateFromTo = destination
      modalLeave = document.getElementById('leave-create-form-dialog')
      break
    }
    case 'CommentDraft': {
      notifications.leaveCommentDraft = true
      notifications.leaveCommentDraftTo = destination
      modalLeave = document.getElementById('leave-comment-draft')
      break
    }
  }
  modalLeave.showModal();
}
const clickMain = () => {
  if (route.path === '/my-tickets/create-new') {
    handleOpenModal('CreateForm','/')
  } else if ((route.path.split('/')[2] === 'ticket')&&((userStore.newComment.text !== '')||(userStore.newComment.media.length > 0))) {
    handleOpenModal('CommentDraft','/')
  } else {
    navigateTo('/')
  }
}
const clickMyTickets = () => {
  if (route.path === '/my-tickets/create-new') {
    handleOpenModal('CreateForm','/my-tickets')
  } else if ((route.path.split('/')[2] === 'ticket')&&((userStore.newComment.text !== '')||(userStore.newComment.media.length > 0))) {
    handleOpenModal('CommentDraft','/my-tickets')
  } else {
    navigateTo(`/my-tickets`)
  }
}
const clickAssignedForMe = () => {
  if (route.path === '/my-tickets/create-new') {
    handleOpenModal('CreateForm', '/assignments')
  } else if ((route.path.split('/')[2] === 'ticket')&&((userStore.newComment.text !== '')||(userStore.newComment.media.length > 0))) {
    handleOpenModal('CommentDraft','/assignments')
  } else {
    navigateTo('/assignments')
  }
}
</script>

<template>
  <div class="flex flex-row direction-horizontal h-[70px] w-full p-0 border-black bg-white">
    <button :class="`relative flex flex-col justify-center items-center w-[33.3%] p-2 `
                    +(route.path === '/'? 'text-cement-orange bg-cement-orange-active ':'text-black bg-white ')
                    +((userStore.testUser.role === 'EXECUTOR')?'w-[33.3%] text-[10px]':'w-[50%]')"
            @click="clickMain">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.5" y="3.5" width="7" height="7" rx="1.5" :stroke="route.path === '/'?'#FF5800':'#000000'"/>
        <rect x="3.5" y="13.5" width="7" height="7" rx="1.5" :stroke="route.path === '/'?'#FF5800':'#000000'"/>
        <rect x="13.5" y="13.5" width="7" height="7" rx="1.5" :stroke="route.path === '/'?'#FF5800':'#000000'"/>
        <circle cx="17" cy="7" r="3.5" :stroke="route.path === '/'?'#FF5800':'#000000'"/>
      </svg>
      <svg class="absolute bottom-[26px] right-[36px]" v-if="generalApp.currentListOfTicketNotifications.length > 0" width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3.25" cy="2.5" r="2.5" fill="#FF5800"/>
      </svg>
      Главная
    </button>
    <button :class="`flex flex-col justify-center items-center p-2 `
                    +((route.path === `/my-tickets`) ? 'text-cement-orange bg-cement-orange-active ':'text-black bg-white ')
                    +((userStore.testUser.role === 'EXECUTOR')?'w-[33.3%] text-[10px]':'w-[50%]')"
            @click="clickMyTickets">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 13.5V8C19 6.89543 18.1046 6 17 6H7C5.89543 6 5 6.89543 5 8V19C5 20.1046 5.89543 21 7 21H12" :stroke="route.path === '/my-tickets'?'#FF5800':'#000000'" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.5 5C8.5 4.17157 9.17157 3.5 10 3.5H14C14.8284 3.5 15.5 4.17157 15.5 5V6H8.5V5Z" :stroke="route.path === '/my-tickets'?'#FF5800':'#000000'"  />
        <line x1="8.5" y1="9.5" x2="15.5" y2="9.5" :stroke="route.path === '/my-tickets'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <line x1="8.5" y1="12.5" x2="15.5" y2="12.5" :stroke="route.path === '/my-tickets'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <line x1="8.5" y1="15.5" x2="12.5" y2="15.5" :stroke="route.path === '/my-tickets'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <path d="M14 17.5714L16.8824 21L21 15" :stroke="route.path === '/my-tickets'?'#FF5800':'#000000'" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Сообщения
    </button>
    <button :class="`text-[10px] flex flex-col justify-center items-center w-[33.3%] p-2 `+((route.path === `/assignments`) ? 'text-cement-orange bg-cement-orange-active':'text-black bg-white')"
            v-if="userStore.testUser.role === 'EXECUTOR'"
            @click="clickAssignedForMe">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="10.5" y1="7.5" x2="21.5" y2="7.5" :stroke="route.path === '/assignments'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <path d="M5.5 5H3V10H8V9" :stroke="route.path === '/assignments'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <path d="M5 6.85714L6.23529 8L8 6" :stroke="route.path === '/assignments'?'#FF5800':'#000000'" stroke-linecap="round" stroke-linejoin="round"/>
        <line x1="10.5" y1="16.5" x2="21.5" y2="16.5" :stroke="route.path === '/assignments'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <path d="M5.5 14H3V19H8V18" :stroke="route.path === '/assignments'?'#FF5800':'#000000'" stroke-linecap="round"/>
        <path d="M5 15.8571L6.23529 17L8 15" :stroke="route.path === '/assignments'?'#FF5800':'#000000'" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      Назначенные Мне
    </button>
  </div>
</template>

<style scoped>

</style>