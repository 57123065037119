<script setup>
import {useNotifications} from "~/stores/notifications";
import {navigateTo} from "#app";

const notifications = useNotifications()
const handleCloseModal = () => {
  const modal = document.getElementById("leave-create-form-dialog");
  modal.close();
  notifications.leaveCreateForm = false
}
const handleLeaveCreateForm = () => {
  handleCloseModal()
  navigateTo(notifications.leaveCreateFromTo)
}
</script>

<template>
  <dialog class="flex flex-col justify-center items-center gap-4 bg-transparent"
          id="leave-create-form-dialog">
    <div class="w-[290px] h-[332px] bg-white p-[20px] flex flex-col justify-center items-center gap-[15px]">
      <h2 class="text-black font-bold text-[16px] text-center">
        Покинуть создание нового отчёта?
      </h2>
      <p class="text-black text-[14px] text-center">
        Мы сохраним всю информацию, чтобы Вы могли отправить его позже.
      </p>
      <button @click="handleCloseModal()"
          class="bg-cement-orange text-white text-[14px] w-[240px] h-[45px] p-1 rounded">
        Продолжить редактирование
      </button>
      <p class="text-black text-[14px] text-center">
        или
      </p>
      <button @click="handleLeaveCreateForm()"
          class="bg-white text-black text-[14px] w-[240px] h-[45px] p-1 ring-cement-orange ring-1 rounded">
        Покинуть
      </button>
    </div>
  </dialog>
</template>

<style scoped>
dialog::backdrop {
  background: rgba(26, 26, 26, 0.75);
}
</style>