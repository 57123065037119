<script setup>
import {useUserStore} from "~/stores/user.js";

const userStore = useUserStore()
const createNewTicket = () => {
  if (userStore.testUser.place !== '') {
    userStore.newTicket.danger_zone = userStore.testUser.place
  }
  userStore.newTicket.media_attachments = []
  navigateTo(`/my-tickets/create-new`)
}
</script>

<template>
<div class="mb-[10px] w-[270px] flex flex-col justify-center items-center direction-horizontal h-[35px] border-black bg-cement-orange text-white">
  <button class="min-w-full p-2" @click="createNewTicket">
    Сообщить о Риске
  </button>
</div>
</template>

<style scoped>

</style>