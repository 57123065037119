<script setup lang="ts">
import {useNotifications} from "~/stores/notifications";

const notifications = useNotifications()
const handleCloseModal = () => {
  const networkError = document.getElementById("network-error-dialog");
  networkError.close();
  notifications.networkError = false
}
</script>

<template>
  <dialog class="flex flex-col justify-center items-center gap-4 bg-transparent"
      id="network-error-dialog">
      <div class="w-[290px] h-[162px] bg-red-network-error p-[20px]">
        <p class="text-white text-[14px]">
          Кажется, возникли проблемы со связью.
          <br>
          <br>
          Попробуйте восстановить доступ к интернету, и попробуйте заказать звонок еще раз.
        </p>
      </div>
      <span class="bg-grey p-1 rounded-[50%]" @click="handleCloseModal">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#888888"/>
        <path d="M15 15H24C26.7614 15 29 17.2386 29 20V20C29 22.7614 26.7614 25 24 25H11" stroke="white" stroke-linecap="round"/>
        <path d="M14 28L11 25L14 22" stroke="white" stroke-linecap="round"/>
        </svg>
      </span>
  </dialog>
</template>

<style scoped>

dialog::backdrop {
  background: rgba(26, 26, 26, 0.75);
}

</style>