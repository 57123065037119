<script setup>
import RegistrationLoginBlock from "~/components/NavigationMenu/RegistrationLoginBlock.vue";
import ConfirmBlock from "~/components/NavigationMenu/ConfirmBlock.vue";
import {useGeneralApp} from "~/stores/generalApp.js";

const generalApp = useGeneralApp();
</script>

<template>
    <RegistrationLoginBlock v-if="!generalApp.confirmLogin&&!generalApp.confirmRegistration&&!generalApp.confirmPasswordReset"/>
    <ConfirmBlock v-if="generalApp.confirmLogin||generalApp.confirmRegistration||generalApp.confirmPasswordReset"/>
</template>

<style scoped>

</style>